<template>
  <v-card class="pa-8">
    <div class="d-flex flex-row justify-space-between">
      <h2 class="pb-8">Input Data</h2>
      <v-btn @click="this.refresh" class="text-capitalize" color="primary">
        <v-icon size="22">{{ this.icons.mdiReload }}</v-icon>
      </v-btn>
    </div>
    <v-form ref="form" v-model="valid">
      <!-- <v-text-field
                            v-model="formData.product"
                            label="Produk"
                            color="primary"
                            :rules="rules.required"
                            outlined
                            dense
                            required
                        ></v-text-field>-->
      <v-select
        :items="product"
        :rules="rules.required"
        outlined
        v-model="formData.product"
        color="primary"
        dense
        required
        label="Produk"
      ></v-select>

      <div v-for="(data, i) in totalID" :key="i">
        <v-text-field
          v-model="formData.nik[i]"
          label="Nomor Identitas (KTP atau NPWP)"
          color="primary"
          outlined
          :rules="rules.required"
          dense
          required
        ></v-text-field>
      </div>
      <v-btn @click="this.plusTotalID" class="text-capitalize" color="primary"
        >Tambah Nomor Identitas</v-btn
      >
    </v-form>
    <button-form :submit="submit" :buttonText="'Input Data'" class="pt-8" />
    <div v-if="this.$store.state.score.dataFdc.length > 0">
      <div v-for="(fdc, i) in this.$store.state.score.dataFdc" :key="i">
        <div class="d-flex flex-row mb-6 mt-5">
          <h4 class="mr-4" style="width: 100px;">No Identitas</h4>
          <h4>:</h4>
          <h4 class="ml-2">{{ fdc.data.noIdentitas }}</h4>
        </div>
        <div class="d-flex flex-row mb-6 mt-5">
          <h4 class="mr-4" style="width: 100px">Catatan</h4>
          <h4>:</h4>
          <h4 class="ml-2">{{ fdc.message }}</h4>
        </div>

        <v-data-table
          :key="i"
          :items="fdc.data.pinjaman"
          :headers="headers"
          disable-pagination
        ></v-data-table>
      </div>
    </div>
    <!-- {{this.$store.state.score.dataFdc[0].data.pinjaman}} -->
    <popup-success :dialog="showDialog" :closeDialog="closeDialog" />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import CardVerticalStats from "../../components/Card/CardVerticalStats.vue";
import FormControl from "../../components/Form/FormControl.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";
import { mdiReload } from "@mdi/js";

export default {
  components: {
    HeaderContent,
    ButtonForm,
    CardVerticalStats,
    FormControl,
    PopupSuccess,
    PopupError,
  },

  data() {
    return {
      icons: {
        mdiReload,
      },
      product: [
        "bni_payroll",
        "smma",
        "bni_kpr",
        "smma_fdc",
        "smma_ktp",
        "invoice_financing",
        "productive_paddy",
        "productive_corn",
      ],

      valid: false,
      headers: [
        { text: "dpd_max", value: "dpd_max" },
        { text: "dpd_terakhir", value: "dpd_terakhir" },
        { text: "id_penyelenggara", value: "id_penyelenggara" },
        { text: "jenis_pengguna", value: "jenis_pengguna" },
        { text: "jenis_pengguna_ket", value: "jenis_pengguna_ket" },
        { text: "kualitas_pinjaman", value: "kualitas_pinjaman" },
        { text: "kualitas_pinjaman_ket", value: "kualitas_pinjaman_ket" },
        { text: "nama_borrower", value: "nama_borrower" },
        { text: "nilai_pendanaan", value: "nilai_pendanaan" },
        { text: "no_identitas", value: "no_identitas" },
        { text: "no_npwp", value: "no_npwp" },
        { text: "sisa_pinjaman_berjalan", value: "sisa_pinjaman_berjalan" },
        { text: "status_pinjaman", value: "status_pinjaman" },
        { text: "status_pinjaman_ket", value: "status_pinjaman_ket" },
        { text: "tgl_jatuh_tempo_pinjaman", value: "tgl_jatuh_tempo_pinjaman" },
        { text: "tgl_pelaporan_data", value: "tgl_pelaporan_data" },
        { text: "tgl_penyaluran_dana", value: "tgl_penyaluran_dana" },
        { text: "tgl_perjanjian_borrower", value: "tgl_perjanjian_borrower" },
      ],
      totalID: 1,
      formData: {
        product: "",
        nik: [],
      },
      rules: {
        required: [(value) => !!value || "Required."],
      },
      showDialog: false,
      showErrDialog: false,
    };
  },

  methods: {
    plusTotalID() {
      // console.log('hello')
      this.totalID += 1;
    },
    refresh() {
      // console.log('hello')
      window.location.reload();
    },
    async submit() {
      this.$refs.form.validate();
      if (this.valid == true) {
        try {
          console.log(this.formData);
          await store.dispatch("score/requestFdc", this.formData);
          this.formData.product = "";
          this.formData.nik = [];
          this.showDialog = true;
        } catch (err) {
          this.showErrDialog = true;
        }
      }
    },
    closeDialog() {
      this.showDialog = !this.showDialog;
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
  },
};
</script>
